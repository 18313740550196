import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/Imagegallery.css';
import heroImage from '../../assets/images/devices_icon.png';
import elg5121Image from '../../assets/images/elg5121.jpg';
import seg3125Image from '../../assets/images/LectureRoom02.jpg'; // Add this image to your assets
import { usePublicRooms } from '../home/usePublicRooms';
import { scaledThumbnailUrlFor } from '../../utils/media-url-utils';
import { bottom } from '@popperjs/core';

const ImageGallery = () => {
  const { results: publicRooms, loading, error } = usePublicRooms();
  const [nonLectureRooms, setNonLectureRooms] = useState([]);
  const [lectureRooms, setLectureRooms] = useState([]);
  const [roomStartRooms, setRoomStartRooms] = useState([]);
  const [showIframe, setShowIframe] = useState(false);
  const [showRoomIframe, setShowRoomIframe] = useState(false);

  useEffect(() => {
    if (publicRooms && publicRooms.length > 0) {
      const formattedRooms = publicRooms
        .sort((a, b) => b.member_count - a.member_count)
        .map((room) => ({
          img: scaledThumbnailUrlFor(room.images?.preview?.url || heroImage),
          url: room.url ? room.url : `/${room.id}`,
          title: room.name || 'Untitled Room',
          memberCount: room.member_count,
          labels: room.labels || [],
        }));

      setLectureRooms(
        formattedRooms.filter((room) =>
          room.title.toLowerCase().includes('lecture')
        )
      );

      setRoomStartRooms(
        formattedRooms.filter((room) =>
          room.title.startsWith('Room')
        )
      );

      setNonLectureRooms(
        formattedRooms.filter(
          (room) => 
            !room.title.toLowerCase().includes('lecture') &&
            !room.title.startsWith('Room')
        )
      );
    }
  }, [publicRooms]);

  const handleELG5121Click = () => {
    setShowIframe(true);
  };

  const handleSEG3125Click = () => {
    setShowRoomIframe(true);
  };

  const handleCardClick = (room) => {
    if (room.title === 'ELG5121') {
      handleELG5121Click();
    } else if (room.title === 'SEG3125/3525') {
      handleSEG3125Click();
    } else {
      window.open(room.url, '_blank');
    }
  };

  const closeIframe = () => {
    setShowIframe(false);
  };

  const closeRoomIframe = () => {
    setShowRoomIframe(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div id="gallery" className="introtitle">
      <div className="gallery-header">
        <span className="expmessage">Experiences made for</span>
        <img src={heroImage} alt="Hero" className="below-title-image" />
      </div>

      <div className="bordered-container">
        <div className="main-content">
          <div className="left-column">
            <h2 style={{ textAlign: 'center' }}>
              <span className="title-prefix">Welcome to </span>
              <span className="title-zapaura">ZapAura</span>
            </h2>
            <p>
              At ZapAura, we empower learning and innovation through immersive
              virtual experiences. The name blends the dynamic energy of "Zap"
              with the illuminating essence of "Aura," symbolizing a vibrant
              and engaging atmosphere for education in the metaverse.
            </p>
            <p>
              Our platform is designed to revolutionize the way we learn,
              connect, and grow. Explore a space where knowledge meets
              creativity, and technology amplifies the educational journey.
            </p>
            <p>
              <span className="explore-text">Explore:</span> Dive into interactive
              virtual environments that bring learning to life.
            </p>
            <p>
              <span className="connect-text">Connect:</span> Collaborate with peers,
              educators, and experts from across the globe.
            </p>
            <p>
              <span className="create-text">Create:</span> Design your own learning
              experiences and contribute to a growing educational ecosystem.
            </p>
            <p>
              Whether you're a student, teacher, or lifelong learner, ZapAura
              is your gateway to a future where education transcends boundaries.
              Discover, innovate, and thrive in a space where curiosity leads
              the way.
            </p>
          </div>
          
          <div className="right-column">
            {/* ELG5121 Card */}
            <div
              className="gallery-card"
              onClick={() => handleCardClick({ title: 'ELG5121' })}
            >
              <div className="gallery-card-inner">
                <img src={elg5121Image} alt="ELG5121" className="card-image" />
                <div className="gallery-card-label">
                  <h3>ELG5121</h3>
                </div>
              </div>
            </div>

            {/* SEG3125/3525 Card */}
            <div
              className="gallery-card"
              onClick={() => handleCardClick({ title: 'SEG3125/3525' })}
            >
              <div className="gallery-card-inner">
                <img src={seg3125Image} alt="SEG3125/3525" className="card-image" />
                <div className="gallery-card-label">
                  <h3>SEG3125/3525</h3>
                </div>
              </div>
            </div>

            {/* Other Non-Lecture Rooms */}
            {nonLectureRooms.map((room) => (
              <div
                key={room.url}
                className="gallery-card"
                onClick={() => handleCardClick(room)}
              >
                <div className="gallery-card-inner">
                  <img src={room.img} alt={room.title} className="card-image" />
                  <div className="gallery-card-label">
                    <h3>{room.title}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* ELG5121 Iframe Modal */}
      {showIframe && (
        <div className="iframe-modal">
          <div className="iframe-content">
            <button onClick={closeIframe} className="close-button">
              ×
            </button>
            <h2>ELG5121 Lecture Rooms</h2>
            <div className="lecture-rooms-list">
              {lectureRooms.map((room) => (
                <div
                  key={room.url}
                  className="lecture-room-item"
                  onClick={() => window.open(room.url, '_blank')}
                >
                  <img src={room.img} alt={room.title} />
                  <span>{room.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* SEG3125/3525 Iframe Modal */}
      {showRoomIframe && (
        <div className="iframe-modal">
          <div className="iframe-content">
            <button onClick={closeRoomIframe} className="close-button">
              ×
            </button>
            <h2>SEG3125/3525 Rooms</h2>
            <div className="lecture-rooms-list">
              {roomStartRooms.map((room) => (
                <div
                  key={room.url}
                  className="lecture-room-item"
                  onClick={() => window.open(room.url, '_blank')}
                >
                  <img src={room.img} alt={room.title} />
                  <span>{room.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;