import './style/Footer.css'; // Ensure you have this CSS file
import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaGoogle, FaGithub } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <h2>For inquiries or collaboration opportunities, contact us at:</h2>
                <p>📧 <a href="mailto:leprof4u@gmail.com" className="email-link">leprof4u@gmail.com</a></p>
                <p>We’d love to hear from you!</p>
                <div className="social-media">
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaFacebook />
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaTwitter />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaInstagram />
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaLinkedin />
                    </a>
                    {/* <a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaGoogle />
                    </a>
                    <a href="https://www.github.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaGithub />
                    </a> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;